<template>
  <div class="pointer" @click="$emit('action')">
    <v-icon>mdi-close</v-icon>
  </div>
</template>

<script>
  export default {
    name: "BtnClose"
  };
</script>

<style></style>
