<template>
  <v-container class="content-page-half">
    <title-header title="Cadastrar Reciclador" :subtitle="subtitle" />
    <register-recycler-first-step
      v-if="step.currentStep === 1"
      @submit="handleFirstStepSubmit"
    />
    <register-recycler-second-step
      v-if="step.currentStep === 2"
      @backAction="handleSecondStepBackAction"
      @submit="handleSecondStepSubmit"
    />
    <register-recycler-third-step
      v-if="step.currentStep === 3"
      @submit="handleThirdStepSubmit"
    />
  </v-container>
</template>

<script>
  import TitleHeader from "@/modules/core/views/components/TitleHeader";
  import RegisterRecyclerFirstStep from "@/modules/recycler/views/components/RegisterRecyclerFirstStep";
  import RegisterRecyclerSecondStep from "@/modules/recycler/views/components/RegisterRecyclerSecondStep";
  import RegisterRecyclerThirdStep from "@/modules/recycler/views/components/RegisterRecyclerThirdStep";

  import { mapGetters, mapActions } from "vuex";
  import store from "@/store";

  import { preparePhonesToSubmit } from "@/modules/core/helpers/phoneHelpers";

  export default {
    name: "RegisterRecycler",

    beforeRouteLeave: (to, from, next) => {
      store.dispatch("recycler/resetCreateState");
      next();
    },

    components: {
      TitleHeader,
      RegisterRecyclerFirstStep,
      RegisterRecyclerSecondStep,
      RegisterRecyclerThirdStep
    },

    mounted() {
      this.setCurrentStep(1);
      this.setMaxSteps(3);
    },

    computed: {
      ...mapGetters({
        step: "recycler/getCreateStep",
        form: "recycler/getFormData",
        recyclerId: "recycler/getCreateDocumentsRecyclerId",
        documents: "recycler/getCreateDocumentsItems"
      }),

      subtitle() {
        return `Passo ${this.step.currentStep} de ${this.step.maxSteps}`;
      }
    },

    methods: {
      ...mapActions("recycler", [
        "setCurrentStep",
        "setMaxSteps",
        "resetCreateState",
        "createRecycler",
        "addDocumentRecycler",
        "setCreateDocumentRecyclerId"
      ]),

      ...mapActions(["toggleSnackbar"]),

      handleFirstStepSubmit() {
        this.setCurrentStep(2);
      },

      handleSecondStepBackAction() {
        this.setCurrentStep(1);
      },

      async handleSecondStepSubmit() {
        await this.saveRecycler();
      },

      handleThirdStepBackAction() {
        this.setCurrentStep(2);
      },

      handleThirdStepSubmit() {
        this.saveDocuments();
      },

      async saveRecycler() {
        try {
          const payload = this.mountRecyclerPayload();

          const response = await this.createRecycler(payload);

          this.toggleSnackbar({
            text:
              response?.data?.message ?? "Reciclador cadastrado com sucesso",
            type: "success"
          });
          this.setCreateDocumentRecyclerId(response.data.id);
          this.setCurrentStep(3);
        } catch (error) {
          this.toggleSnackbar({
            text:
              error?.data?.message ??
              `Ocorreu um erro: ${error}, tente novamente`,
            type: "error"
          });
        }
      },

      async saveDocuments() {
        try {
          const payload = this.mountRecyclerDocumentsPayload();

          await this.addDocumentRecycler(payload);

          this.toggleSnackbar({
            text: "Documentos adicionados com sucesso!",
            type: "success"
          });

          this.$router.push({ name: "recicladores" });
        } catch (error) {
          this.toggleSnackbar({
            text:
              error?.data?.message ??
              `Ocorreu um erro: ${error}, tente novamente`,
            type: "error"
          });
        }
      },

      mountRecyclerPayload() {
        return {
          ...this.form,
          phones: preparePhonesToSubmit(this.form.phones)
        };
      },

      mountRecyclerDocumentsPayload() {
        return {
          payload: { documents: this.documents },
          id: this.recyclerId
        };
      }
    }
  };
</script>

<style lang="scss" scoped></style>
