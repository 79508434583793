<template>
  <data-not-found :title="title" :imgSrc="require('@/assets/documents.svg')" />
</template>

<script>
  import DataNotFound from "@/modules/core/views/components/DataNotFound.vue";

  export default {
    name: "DocumentNotFound",

    props: {
      title: {
        type: Array,
        required: true
      }
    },

    components: { DataNotFound }
  };
</script>

<style></style>
