<template>
  <div>
    <v-dialog v-model="state.dialog" scrollable :persistent="true" width="588">
      <validation-observer>
        <v-form @submit.prevent="submitForm" slot-scope="{ invalid }">
          <v-card class="modal">
            <v-card-title class="modal-title"
              >Informações do Documento</v-card-title
            >
            <v-divider></v-divider>

            <v-card-text class="pa-0">
              <div class="mx-7 mt-5">
                <v-row dense v-if="typeDocuments">
                  <v-col cols="12">
                    <field
                      v-model="form.typeDocument"
                      name="type"
                      type="select"
                      label="Tipo de Documento"
                      :items="typeDocuments"
                      item-text="label"
                      :isRequired="true"
                      return-object
                    />
                  </v-col>
                </v-row>

                <v-row dense>
                  <v-col cols="12" md="6">
                    <field
                      v-model="form.number"
                      type="genericText"
                      label="Número do documento"
                      name="number"
                      :isRequired="true"
                    />
                  </v-col>
                  <v-col cols="12" md="6">
                    <date-field
                      v-model="form.expiresAt"
                      label="Data de vencimento"
                      :isRequired="true"
                    />
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12">
                    <upload-file @uploadFile="updateFile" />
                  </v-col>
                </v-row>
              </div>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions class="d-flex flex-wrap justify-space-between">
              <v-btn
                class="mt-1 black-3--text cancel-button"
                color="#ffffff"
                :block="$isMobile"
                @click="state.dialog = false"
                >Cancelar</v-btn
              >
              <v-btn
                type="submit"
                :disabled="invalid || !hasFile"
                color="secondary-color-1"
                class="ma-0 mt-1 white--text next-step-button"
                :block="$isMobile"
                >Adicionar</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-form>
      </validation-observer>
    </v-dialog>
  </div>
</template>

<script>
  import Field from "@/modules/core/views/components/Field";
  import DateField from "@/modules/core/views/components/DateField";
  import UploadFile from "@/modules/core/views/components/UploadFile";

  import { ValidationObserver } from "vee-validate";

  import { mapActions, mapGetters } from "vuex";

  export default {
    name: "AddDocumentDialog",

    props: {
      state: {
        type: Object,
        required: true
      },
      fnSubmit: {
        type: Function,
        required: true
      },
      recyclerId: {
        type: Number,
        required: true
      }
    },

    components: {
      ValidationObserver,
      Field,
      DateField,
      UploadFile
    },

    data: () => ({
      typeDocuments: [],

      form: {
        typeDocument: undefined,
        expiresAt: undefined,
        number: undefined,
        file: undefined
      }
    }),

    mounted() {
      this.fetchTypeDocuments();
    },

    computed: {
      hasFile() {
        return !!this.form?.file?.id;
      }
    },

    methods: {
      ...mapActions("recycler", ["getRecyclerDocumentType"]),
      ...mapActions(["toggleSnackbar"]),

      async fetchTypeDocuments() {
        if (!this.recyclerId) {
          throw new Error("recyclerId prop MUST be defined");
        }
        try {
          const { data } = await this.getRecyclerDocumentType(this.recyclerId);
          this.typeDocuments = this.formatTypeDocuments(data);
        } catch (error) {
          this.toggleSnackbar({
            text: error?.data?.message ?? "Ocorreu um erro. Tente novamente",
            type: "error"
          });
        }
      },

      formatTypeDocuments(data) {
        return data.map(item => {
          item.label = `${item.initials} (${item.description})`;
          return item;
        });
      },

      updateFile(file) {
        this.form.file = { ...file };
      },

      submitForm() {
        this.state.dialog = false;
        this.fnSubmit({
          ...this.form,
          typeDocument: {
            ...this.form.typeDocument
          },
          file: {
            ...this.form.file
          }
        });
      }
    }
  };
</script>

<style></style>
