<template>
  <v-card class="pt-5">
    <v-form @submit.prevent="submitForm">
      <div :class="{ 'px-10': $isDesktop || $isTablet, 'px-5': $isMobile }">
        <h3>Informe quais resíduos são recolhidos por essa empresa:</h3>
        <v-expansion-panels accordion flat class="mt-5">
          <expansion-panel v-for="(item, i) in residuesType" :key="i">
            <template slot="title">
              {{ item.description }}
            </template>
            <template slot="content">
              <v-layout class="flex-wrap pt-4 px-3">
                <v-checkbox
                  v-for="(res, i) in item.residueSubtypes"
                  :key="i"
                  :multiple="true"
                  v-model="innerForm.residueSubtypes"
                  :label="res.description"
                  :value="{ id: res.id }"
                  class="ma-0 item"
                  dense
                ></v-checkbox>
              </v-layout>
            </template>
          </expansion-panel>
        </v-expansion-panels>
      </div>
      <v-card-actions class="d-flex flex-wrap justify-space-between">
        <v-btn
          type="button"
          @click="handleBackAction"
          color="#ffffff"
          class="mt-1 cancel-button black-3--text"
          >Voltar</v-btn
        >
        <v-btn
          type="submit"
          :disabled="innerForm.residueSubtypes.length <= 0"
          color="secondary-color-1"
          class="ma-0 mt-1 next-step-button white--text"
          >Salvar</v-btn
        >
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
  import ExpansionPanel from "@/modules/core/views/components/ExpansionPanel";
  import { mapActions, mapGetters } from "vuex";

  export default {
    name: "RegisterRecycleSecondStep",

    data: () => ({
      selectedResidues: [],
      residuesType: [],
      residueSubtypes: []
    }),

    async mounted() {
      await this.getResiduesMethod();
    },

    computed: {
      ...mapGetters("recycler", {
        form: "getFormData"
      }),
      innerForm: {
        get() {
          return this.form;
        },
        set(newVal) {
          this.setFormData(newVal);
        }
      }
    },

    components: {
      ExpansionPanel
    },

    methods: {
      ...mapActions(["toggleSnackbar"]),
      ...mapActions("recycler", [
        "getResidues",
        "setFormData",
        "setCreateResiduesItems",
        "setCurrentStep"
      ]),

      async getResiduesMethod(resType) {
        try {
          const { data } = await this.getResidues();

          this.residuesType = data;
        } catch (err) {
          this.toggleSnackbar({
            text: "Algo deu errado.",
            type: "error"
          });
        }
      },

      async setResidues(value) {
        await this.getResiduesMethod(value);
      },

      submitForm() {
        this.setFormData(this.innerForm);
        this.$emit("submit");
      },

      handleBackAction() {
        this.setCurrentStep(1);
      }
    }
  };
</script>

<style lang="scss" scoped>
  .item {
    min-width: 240px;
  }
</style>
