<template>
  <v-card class="pt-5">
    <div :class="{ 'px-10': $isDesktop || $isTablet, 'px-5': $isMobile }">
      <h3>Adicionar Documento</h3>
      <v-row>
        <v-col cols="12">
          <btn-dashed
            :btnAction="prepareCreateDocument"
            depressed
            block
            title="Adicionar Documento"
          />
        </v-col>
      </v-row>
      <div class="pt-10" v-if="!documents.length">
        <document-not-found :title="documentNotFound" />
      </div>
      <div
        v-for="(document, index) in documents"
        :key="`document-${index}`"
        class="document-item-container mt-3 pa-4 d-flex justify-space-between"
      >
        <div>
          <p class="ma-0 black-1--text">
            <strong>
              {{ document.typeDocument.initials }} -
              {{ document.typeDocument.description }}
            </strong>
          </p>
          <p class="ma-0">{{ formatDate(document.expiresAt) }}</p>
        </div>
        <div class="d-flex align-center">
          <btn-close @action="handleRemoveDocument(document)" />
        </div>
      </div>
    </div>

    <add-document-dialog
      :key="`add-document-dialog-key-${addDocumentDialogKey}`"
      :recyclerId="recyclerId"
      :state="addDocumentDialogState"
      :fnSubmit="handleSubmitDocument"
    />

    <v-card-actions class="d-flex flex-wrap justify-space-between">
      <v-btn
        color="#FFF"
        :block="$isMobile"
        class="mt-1 cancel-button black-3--text"
        @click="$router.push({ name: 'recicladores' })"
        >Cancelar</v-btn
      >
      <v-btn
        color="secondary-color-1"
        :block="$isMobile"
        :disabled="!hasDocuments"
        class="ma-0 mt-1 next-step-button white--text"
        @click="submitForm"
        >Salvar</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
  import BtnDashed from "@/modules/core/views/components/BtnDashed";
  import AddDocumentDialog from "@/modules/recycler/views/components/AddDocumentDialog";
  import DocumentNotFound from "@/modules/core/views/components/DocumentNotFound";
  import BtnClose from "@/modules/core/views/components/BtnClose";

  import { ptBR } from "date-fns/locale";
  import { format, addDays } from "date-fns";

  import { mapActions, mapGetters } from "vuex";

  export default {
    name: "RegisterRecyclerThirdStep",

    components: {
      BtnDashed,
      AddDocumentDialog,
      BtnClose,
      DocumentNotFound
    },

    data: () => ({
      autoIncrement: 1,
      documents: [],

      addDocumentDialogKey: 1,
      addDocumentDialogState: {
        dialog: false
      },

      documentNotFound: [
        "Ainda não existem documentos cadastrados.",
        "Adicione o primeiro documento."
      ]
    }),

    watch: {
      "addDocumentDialogState.dialog": function(newVal) {
        if (!newVal) {
          this.addDocumentDialogKey++;
        }
      }
    },

    computed: {
      ...mapGetters("recycler", {
        recyclerId: "getCreateDocumentsRecyclerId"
      }),

      hasDocuments() {
        return this.documents?.length;
      }
    },

    methods: {
      ...mapActions("recycler", ["setCreateDocumentItems"]),

      prepareCreateDocument() {
        this.addDocumentDialogState.dialog = true;
      },

      handleSubmitDocument(document) {
        document.index = this.autoIncrement++;
        this.documents.push(document);
      },

      formatDate(date) {
        const formattedDate = format(
          addDays(new Date(date), 1),
          "'Válido até o dia' dd 'de' MMMM 'de' yyyy",
          {
            locale: ptBR
          }
        );

        return formattedDate;
      },

      handleRemoveDocument(document) {
        this.documents = this.documents.filter(doc => {
          return doc.index !== document.index;
        });
      },

      submitForm() {
        this.setCreateDocumentItems(this.documents);
        this.$emit("submit");
      }
    }
  };
</script>

<style lang="scss" scoped>
  @import "@/assets/stylesheets/styleguide";

  .document-item-container {
    border-radius: 6px;
    background-color: $background;
  }
</style>
