<template>
  <v-card class="content mt-3 pt-10">
    <validation-observer>
      <v-form @submit.prevent="submitForm" slot-scope="{ invalid }">
        <div :class="{ 'px-10': $isDesktop || $isTablet, 'px-5': $isMobile }">
          <h3>Informações da empresa</h3>
          <v-row>
            <v-col cols="12">
              <field
                v-model="form.name"
                type="genericText"
                label="Nome da empresa"
                name="name"
                :isRequired="true"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <field
                v-model="form.cnpj"
                type="cnpj"
                label="CNPJ"
                name="cnpj"
                :isRequired="true"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <field
                v-model="form.site"
                type="genericText"
                label="Site"
                name="site"
                :isRequired="false"
              />
            </v-col>
          </v-row>
          <h3>Endereço</h3>
          <address-form :address="form.address" :isRequired="true" />

          <h3>
            O Reciclador realiza exportações?
          </h3>

          <v-row>
            <v-col cols="12">
              <v-radio-group class="radio-exports" v-model="form.exporter">
                <v-radio :color="'#3CA897'" :value="true">
                  <template v-slot:label>
                    <span
                      >Sim, o reciclador
                      <strong>realiza</strong> exportações.</span
                    >
                  </template>
                </v-radio>
                <v-radio :color="'#3CA897'" :value="false">
                  <template v-slot:label>
                    <span
                      >Não, o reciclador
                      <strong>não realiza</strong> exportações.</span
                    >
                  </template>
                </v-radio>
              </v-radio-group>
            </v-col>
          </v-row>

          <h3>Representante</h3>
          <v-row>
            <v-col cols="12">
              <field
                v-model="form.recyclerAgent"
                type="genericText"
                label="Nome completo"
                name="nome"
                :isRequired="true"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <field
                v-model="form.email"
                type="email"
                label="E-mail"
                name="email"
                :isRequired="true"
              />
            </v-col>
          </v-row>
          <phone-field v-model="form.phones" :isRequired="true" />
        </div>
        <v-card-actions class="d-flex flex-wrap justify-space-between">
          <v-btn
            @click="$router.push({ name: 'recicladores' })"
            color="#FFF"
            :block="$isMobile"
            class="mt-1 cancel-button black-3--text"
            >Cancelar</v-btn
          >
          <v-btn
            type="submit"
            color="secondary-color-1"
            :disabled="invalid"
            :block="$isMobile"
            class="ma-0 mt-1 next-step-button white--text"
            >Próximo</v-btn
          >
        </v-card-actions>
      </v-form>
    </validation-observer>
  </v-card>
</template>

<script>
  import { ValidationObserver } from "vee-validate";
  import { mapGetters, mapActions } from "vuex";
  import { debounce } from "lodash";

  import Field from "@/modules/core/views/components/Field";
  import PhoneField from "@/modules/core/views/components/PhoneField";
  import AddressForm from "@/modules/core/views/components/AddressForm.vue";

  export default {
    name: "RegisterRecyclerFirstStep",

    components: {
      ValidationObserver,
      Field,
      PhoneField,
      AddressForm
    },

    computed: {
      ...mapGetters("recycler", {
        form: "getFormData"
      })
    },

    watch: {
      form: {
        deep: true,
        handler: function(newVal) {
          this.debounceSaveData();
        }
      }
    },

    methods: {
      ...mapActions("recycler", ["setCurrentStep", "setFormData"]),

      submitForm() {
        this.setFormData(this.form);
        this.$emit("submit");
      },

      debounceSaveData: debounce(function() {
        this.setFormData(this.form);
      }, 500)
    }
  };
</script>

<style></style>
